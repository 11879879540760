<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="id_reclamos"
          show-select
          v-model="reclamos_seleccionados"
        >
          <template v-slot:top>
            <v-toolbar flat dense>
              <v-toolbar-title>Reclamos</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                label="Buscar"
                outlined
                dense
                hide-details
              ></v-text-field>
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mx-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Filtrar por
                  </v-btn>
                </template>
                <v-card width="300">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filtro_estados"
                          label="Estado"
                          :items="items_estados"
                          item-text="descripcion"
                          item-value="id_tipo_reclamo"
                          multiple
                          class="mx-1"
                          outlined
                          dense
                          hide-details
                          @change="listarReclamosFiltrado()"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filtro_asegurados"
                          label="Asegurado"
                          :items="items_clientes"
                          item-text="nombre"
                          item-value="id_cliente"
                          class="mx-1"
                          outlined
                          dense
                          hide-details
                          multiple
                          chips
                          small-chips
                          deletable-chips
                          @change="listarReclamosFiltrado()"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filtro_ejecutivos"
                          label="Ejecutivo"
                          :items="items_ejecutivos"
                          item-text="nombre"
                          class="mx-1"
                          outlined
                          dense
                          hide-details
                          multiple
                          @change="listarReclamosFiltrado()"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="dialog_reclamos = !dialog_reclamos"
                >Nuevo</v-btn
              >
            </v-toolbar>
          </template>
          <template v-slot:[`item.action_edit`]="{ item }">
            <v-icon @click="editarReclamo(item)">far fa-edit</v-icon>
          </template>
          <template
            v-slot:[`body.append`]
            v-if="reclamos_seleccionados.length > 0"
          >
            <v-btn color="primary" class="ma-1" @click="descargarExcel()"
              >Descargar</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog_reclamos" width="1500" persistent scrollable>
      <v-card>
        <v-card-text>
          <v-row class="mt-1">
            <v-col cols="3">
              <v-autocomplete
                v-model="tipo_reclamo"
                label="Tipo"
                :items="items_tipo"
                item-text="nombre"
                item-value="tipo"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="ejecutivo"
                label="Ejecutivo"
                :items="items_ejecutivos"
                item-text="nombre"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-icon
                size="30"
                color="black"
                @click="dialog_reclamos = !dialog_reclamos"
                >far fa-times-circle</v-icon
              >
            </v-col>
          </v-row>
          <!-- AUTOS -->
          <v-row v-if="tipo_reclamo == 'auto'">
            <v-col cols="12" md="4">
              <v-text-field
                v-model="num_reclamo"
                label="Reclamo"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="poliza"
                :items="items_reclamos"
                item-text="num_poliza"
                label="Póliza"
                outlined
                dense
                hide-details
                :search-input.sync="search"
                hide-no-data
                @input="setCliente()"
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="placa_nombre"
                label="Placa o Nombre"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="fecha_reclamo"
                label="Fecha de reclamo"
                outlined
                dense
                hide-details
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="fecha_pago"
                label="Fecha de pago"
                outlined
                dense
                hide-details
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="cliente"
                label="Cliente"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="deducible"
                label="Deducible"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nom_cliente"
                label="Nombre de cliente"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="aseguradora"
                label="Aseguradora"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="taller"
                label="Taller"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="caracteristicas_auto"
                label="Características de Auto"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="ajustador"
                label="Ajustador"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="analista"
                label="Analista"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="estado"
                :items="items_estados"
                item-value="id_tipo_reclamo"
                item-text="descripcion"
                label="Estado"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="valor_indemnizado"
                label="Valor indemnizado"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="motivo"
                label="Motivo"
                outlined
                dense
                hide-details
                row-height="1"
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- INCENDIO Y DIVERSOS -->
          <v-row v-if="tipo_reclamo == 'diversos'">
            <v-col cols="12" md="4">
              <v-text-field
                v-model="num_reclamo"
                label="Reclamo"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="poliza"
                :items="items_reclamos"
                item-text="num_poliza"
                label="Póliza"
                outlined
                dense
                hide-details
                :search-input.sync="search"
                hide-no-data
                @input="setCliente()"
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="tipo_seguro"
                label="Tipo de seguro"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="ubicacion"
                label="Ubicación"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="num_riesgo"
                label="# de riesgo"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="cliente"
                label="Cliente"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="deducible"
                label="Deducible"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="participacion"
                label="Participación"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nom_cliente"
                label="Nombre de cliente"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="aseguradora"
                label="Aseguradora"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="ajustador"
                label="Ajustador"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="analista"
                label="Analista"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="estado"
                :items="items_estados"
                item-value="id_tipo_reclamo"
                item-text="descripcion"
                label="Estado"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="valor_indemnizado"
                label="Valor indemnizado"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="motivo"
                label="Motivo"
                outlined
                dense
                hide-details
                row-height="1"
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- TRANSPORTE DE MERCADERIA INTERNO -->
          <v-row v-if="tipo_reclamo == 'transporte'">
            <v-col cols="12" md="4">
              <v-text-field
                v-model="num_reclamo"
                label="Reclamo"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="poliza"
                :items="items_reclamos"
                item-text="num_poliza"
                label="Póliza"
                outlined
                dense
                hide-details
                :search-input.sync="search"
                hide-no-data
                @input="setCliente()"
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="tipo_seguro"
                label="Tipo de seguro"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="num_certificado"
                label="# de certificado"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="num_factura"
                label="# de factura"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="cliente"
                label="Cliente"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="deducible"
                label="Deducible"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="participacion"
                label="Participación"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nom_cliente"
                label="Nombre de cliente"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="aseguradora"
                label="Aseguradora"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="ajustador"
                label="Ajustador"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="analista"
                label="Analista"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="estado"
                :items="items_estados"
                item-value="id_tipo_reclamo"
                item-text="descripcion"
                label="Estado"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="valor_indemnizado"
                label="Valor indemnizado"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="motivo"
                label="Motivo"
                outlined
                dense
                hide-details
                row-height="1"
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- PERSONAS -->
          <v-row v-if="tipo_reclamo == 'personas'">
            <v-col cols="12" md="4">
              <v-text-field
                v-model="num_reclamo"
                label="Reclamo"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="poliza"
                :items="items_reclamos"
                item-text="num_poliza"
                label="Póliza"
                outlined
                dense
                hide-details
                :search-input.sync="search"
                hide-no-data
                @input="setCliente()"
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="tipo_seguro"
                label="Tipo de seguro"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="hospital_clinica"
                label="Hospital o clinica"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="medico"
                label="Médico"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="cliente"
                label="Cliente"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="deducible"
                label="Deducible"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="participacion"
                label="Participación"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nom_cliente"
                label="Nombre de cliente"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="aseguradora"
                label="Aseguradora"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="analista"
                label="Analista"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="estado"
                :items="items_estados"
                item-value="id_tipo_reclamo"
                item-text="descripcion"
                label="Estado"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="valor_indemnizado"
                label="Valor indemnizado"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="motivo"
                label="Motivo"
                outlined
                dense
                hide-details
                row-height="1"
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- GESTIONES -->
          <v-row>
            <v-col cols="12">
              <v-btn color="grey" dark @click="agregar_gestion()"
                >Agregar gestión</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-row cols="12" v-for="(item, i) in items_gestiones" :key="i">
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="item.fecha_gestion"
                    label="Fecha de gestion"
                    outlined
                    dense
                    hide-details
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="10">
                  <v-textarea
                    v-model="item.gestion"
                    label="Gestion"
                    outlined
                    dense
                    hide-details
                    auto-grow
                    row-height="1"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="crearReclamo()" v-if="flag == 1"
            >Guardar</v-btn
          >
          <v-btn color="primary" @click="actualizar()" v-if="flag == 2"
            >Actualizar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="limpiar()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
var XLSX = require("xlsx");

export default {
  data: () => ({
    reclamos_seleccionados: [],
    tipo_reclamo: "auto",
    items_tipo: [
      { tipo: "auto", nombre: "AUTO" },
      { tipo: "diversos", nombre: "INCENDIO/DIVERSOS" },
      { tipo: "transporte", nombre: "TRANSPORTE MERCADERIA" },
      { tipo: "personas", nombre: "PERSONAS" },
    ],
    dialog_reclamos: false,
    headers: [
      { text: "N° Reclamo", value: "num_reclamo" },
      { text: "N° Póliza", value: "num_poliza" },
      { text: "Asegurado", value: "nombre_cliente" },
      { text: "Ramo", value: "ramo" },
      { text: "Ejecutivo", value: "ejecutivo" },
      { text: "Estado", value: "nombre_tipo_reclamo" },
      { text: "Editar", value: "action_edit", align: "center" },
    ],
    items: [],
    poliza: null,
    id_poliza: "",
    num_reclamo: "",
    num_poliza: "",
    placa_nombre: "",
    tipo_seguro: "",
    ubicacion: "",
    num_riesgo: "",
    num_certificado: "",
    num_factura: "",
    hospital_clinica: "",
    medico: "",
    fecha_reclamo: "",
    fecha_pago: "",
    cliente: "",
    participacion: "",
    deducible: "",
    nom_cliente: "",
    aseguradora: "",
    taller: "",
    caracteristicas_auto: "",
    ajustador: "",
    analista: "",
    estado: null,
    filtro_estados: [],
    filtro_asegurados: [],
    filtro_ejecutivos: [],
    items_estados: [],
    valor_indemnizado: "",
    motivo: "",
    ejecutivo: "",
    items_ejecutivos: [
      { id_usuario: 2, id_rol: 1, nombre: "Mariana López", usuario: "mlopez" },
      {
        id_usuario: 7,
        id_rol: 1,
        nombre: "Luis Adonay Vasquez",
        usuario: "Lvasquez",
      },
      {
        id_usuario: 8,
        id_rol: 1,
        nombre: "José Alexander Ayala",
        usuario: "Jayala",
      },
      {
        id_usuario: 9,
        id_rol: 1,
        nombre: "Nelson Daniel Claros",
        usuario: "Nclaros",
      },
    ],
    search: null,
    items_reclamos: [],
    flag: 1,
    fecha_gestion: "",
    gestion: "",
    items_gestiones: [],
    items_filtros: [
      { cod: 1, nombre: "Estado" },
      { cod: 2, nombre: "Asegurado" },
      { cod: 3, nombre: "Ejecutivo" },
    ],
    items_clientes: [],
  }),
  created() {
    this.listarReclamos();
    this.listarEstados();
    this.listarClientes();
  },
  watch: {
    search(valor) {
      axios.get("api/Polizas/FiltrarNumPoliza/" + valor).then((response) => {
        this.items_reclamos = response.data;
      });
    },
  },
  methods: {
    listarReclamos() {
      axios.get("api/Reclamos/Listar").then((response) => {
        //console.log(response.data);
        this.items = response.data;
      });
    },
    listarReclamosFiltrado() {
      axios
        .post("api/Reclamos/Filtrado", {
          id_tipo_reclamos: this.filtro_estados,
          id_cliente: this.filtro_asegurados,
          ejecutivo: this.filtro_ejecutivos,
        })
        .then((response) => {
          //console.log(response.data);
          this.items = response.data;
        });
    },
    listarEstados() {
      axios.get("api/TipoReclamos/Listar").then((response) => {
        //console.log(response.data);
        this.items_estados = response.data;
      });
    },
    listarClientes() {
      axios.get("api/Clientes/Listar").then((response) => {
        //console.log(response.data);
        this.items_clientes = response.data;
      });
    },
    editarReclamo(item) {
      //console.log(item);
      if (item.cod_ramo == "03") {
        this.tipo_reclamo = "auto";
      } else if (
        item.cod_ramo == "06" ||
        item.cod_ramo == "07" ||
        item.cod_ramo == "13" ||
        item.cod_ramo == "17"
      ) {
        this.tipo_reclamo = "personas";
      } else if (
        item.cod_ramo == "01" ||
        item.cod_ramo == "04" ||
        item.cod_ramo == "05" ||
        item.cod_ramo == "08" ||
        item.cod_ramo == "09" ||
        item.cod_ramo == "10" ||
        item.cod_ramo == "14" ||
        item.cod_ramo == "16" ||
        item.cod_ramo == "18"
      ) {
        this.tipo_reclamo = "diversos";
      } else if (item.cod_ramo == "11" || item.cod_ramo == "12") {
        this.tipo_reclamo = "transporte";
      }

      this.id_reclamo = item.id_reclamos;
      this.id_poliza = item.id_polizas;
      this.estado = item.id_tipo_reclamos;
      this.num_reclamo = item.num_reclamo;
      this.num_poliza = item.num_poliza;
      this.placa_nombre = item.placa_nombre;
      this.fecha_reclamo = this.formatoFecha(item.fecha_reclamo);
      this.fecha_pago = this.formatoFecha(item.fecha_pago);
      this.cliente = item.codigo_cliente;
      this.deducible = item.deducible;
      this.nom_cliente = item.nombre_cliente;
      this.aseguradora = item.aseguradora;
      this.taller = item.taller;
      this.caracteristicas_auto = item.caracteristicas_auto;
      this.ajustador = item.ajustador;
      this.analista = item.analista;
      this.valor_indemnizado = item.valor_indemnizado;
      this.motivo = item.motivo;
      this.ejecutivo = item.ejecutivo;

      this.listarGestiones();

      this.flag = 2;
      this.dialog_reclamos = true;
    },
    listarGestiones() {
      axios
        .get("api/ReclamosGestiones/Listar/" + this.id_reclamo)
        .then((response) => {
          //console.log(response.data);
          for (var i = 0; i < response.data.length; i++) {
            this.items_gestiones.push({
              id_gestion_reclamo: response.data[i].id_gestion_reclamo,
              id_reclamos: response.data[i].id_reclamos,
              fecha_gestion: this.formatoFecha(response.data[i].fecha_gestion),
              gestion: response.data[i].gestion,
            });
          }
        });
    },
    setCliente() {
      //console.log(this.poliza);
      this.id_poliza = this.poliza.id_polizas;
      this.cliente = this.poliza.codigo_cliente;
      this.nom_cliente = this.poliza.nombre_cliente;
      this.aseguradora = this.poliza.nombre_aseguradora;
    },
    crearReclamo() {
      axios
        .post("api/Reclamos/Crear", {
          id_polizas: this.id_poliza,
          id_tipo_reclamos: this.estado,
          num_reclamo: this.num_reclamo,
          fecha_pago: this.fecha_pago,
          fecha_reclamo: this.fecha_reclamo,
          taller: this.taller,
          motivo: this.motivo,
          valor_indemnizado: this.valor_indemnizado,
          placa_nombre: this.placa_nombre,
          caracteristicas_auto: this.caracteristicas_auto,
          deducible: this.deducible,
          aseguradora: this.aseguradora,
          analista: this.analista,
          tipo_seguro: this.tipo_seguro,
          ubicacion: this.ubicacion,
          num_riesgo: this.num_riesgo,
          participacion: this.participacion,
          num_certificado: this.num_certificado,
          num_factura: this.num_factura,
          hospital_clinica: this.hospital_clinica,
          medico: this.medico,
          es_eliminado: 0,
          ajustador: this.ajustador,
          ejecutivo: this.ejecutivo,
          gestiones: this.items_gestiones,
        })
        .then(() => {
          this.limpiar();
          this.listarReclamos();
          this.alerta("success", "Registro creado con exito");
        })
        .catch(() => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    actualizar() {
      axios
        .put("api/Reclamos/Actualizar", {
          id_reclamos: this.id_reclamo,
          id_polizas: this.id_poliza,
          id_tipo_reclamos: this.estado,
          num_reclamo: this.num_reclamo,
          fecha_pago: this.fecha_pago,
          fecha_reclamo: this.fecha_reclamo,
          taller: this.taller,
          motivo: this.motivo,
          valor_indemnizado: this.valor_indemnizado,
          placa_nombre: this.placa_nombre,
          caracteristicas_auto: this.caracteristicas_auto,
          deducible: this.deducible,
          aseguradora: this.aseguradora,
          analista: this.analista,
          tipo_seguro: this.tipo_seguro,
          ubicacion: this.ubicacion,
          num_riesgo: this.num_riesgo,
          participacion: this.participacion,
          num_certificado: this.num_certificado,
          num_factura: this.num_factura,
          hospital_clinica: this.hospital_clinica,
          medico: this.medico,
          ajustador: this.ajustador,
          ejecutivo: this.ejecutivo,
          gestiones: this.items_gestiones,
        })
        .then(() => {
          this.limpiar();
          this.listarReclamos();
          this.alerta("success", "Registro actualizado con exito");
        })
        .catch(() => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    agregar_gestion() {
      this.items_gestiones.push({ fecha_gestion: "", gestion: "" });
    },
    descargarExcel() {
      console.log(this.reclamos_seleccionados);
      var obj = [];
      for (var i = 0; i < this.reclamos_seleccionados.length; i++) {
        if (this.reclamos_seleccionados[i].cod_ramo == "03") {
          //AUTO
          obj.push({
            Reclamo: this.reclamos_seleccionados[i].num_reclamo,
            Póliza: this.reclamos_seleccionados[i].num_poliza,
            "Placa o Nombre": this.reclamos_seleccionados[i].placa_nombre,
            "Fecha de reclamo": this.reclamos_seleccionados[i].fecha_reclamo,
            "Fecha de pago": this.reclamos_seleccionados[i].fecha_pago,
            Cliente: this.reclamos_seleccionados[i].codigo_cliente,
            Deducible: this.reclamos_seleccionados[i].deducible,
            "Nombre de cliente": this.reclamos_seleccionados[i].nombre_cliente,
            Aseguradora: this.reclamos_seleccionados[i].aseguradora,
            Taller: this.reclamos_seleccionados[i].taller,
            "Caracteristica de auto":
              this.reclamos_seleccionados[i].caracteristicas_auto,
            Ajustador: this.reclamos_seleccionados[i].ajustador,
            Analista: this.reclamos_seleccionados[i].analista,
            Estado: this.reclamos_seleccionados[i].nombre_tipo_reclamo,
            "Valor indemnizado":
              this.reclamos_seleccionados[i].valor_indemnizado,
            Motivo: this.reclamos_seleccionados[i].motivo,
          });
        } else if (
          this.reclamos_seleccionados[i].cod_ramo == "06" ||
          this.reclamos_seleccionados[i].cod_ramo == "07" ||
          this.reclamos_seleccionados[i].cod_ramo == "13" ||
          this.reclamos_seleccionados[i].cod_ramo == "17"
        ) {
          //PERSONAS
          obj.push({
            Reclamo: this.reclamos_seleccionados[i].num_reclamo,
            Póliza: this.reclamos_seleccionados[i].num_poliza,
            "Tipo de seguro": this.reclamos_seleccionados[i].tipo_seguro,
            "Hospital o clinica":
              this.reclamos_seleccionados[i].hospital_clinica,
            Médico: this.reclamos_seleccionados[i].medico,
            "Fecha de reclamo": this.reclamos_seleccionados[i].fecha_reclamo,
            "Fecha de pago": this.reclamos_seleccionados[i].fecha_pago,
            Cliente: this.reclamos_seleccionados[i].codigo_cliente,
            Deducible: this.reclamos_seleccionados[i].deducible,
            Participación: this.reclamos_seleccionados[i].participacion,
            "Nombre de cliente": this.reclamos_seleccionados[i].nombre_cliente,
            Aseguradora: this.reclamos_seleccionados[i].aseguradora,
            Analista: this.reclamos_seleccionados[i].analista,
            Estado: this.reclamos_seleccionados[i].nombre_tipo_reclamo,
            "Valor indemnizado":
              this.reclamos_seleccionados[i].valor_indemnizado,
            Motivo: this.reclamos_seleccionados[i].motivo,
          });
        } else if (
          this.reclamos_seleccionados[i].cod_ramo == "01" ||
          this.reclamos_seleccionados[i].cod_ramo == "04" ||
          this.reclamos_seleccionados[i].cod_ramo == "05" ||
          this.reclamos_seleccionados[i].cod_ramo == "08" ||
          this.reclamos_seleccionados[i].cod_ramo == "09" ||
          this.reclamos_seleccionados[i].cod_ramo == "10" ||
          this.reclamos_seleccionados[i].cod_ramo == "14" ||
          this.reclamos_seleccionados[i].cod_ramo == "16" ||
          this.reclamos_seleccionados[i].cod_ramo == "18"
        ) {
          //INCENDIO Y DIVERSOS
          obj.push({
            Reclamo: this.reclamos_seleccionados[i].num_reclamo,
            Póliza: this.reclamos_seleccionados[i].num_poliza,
            "Tipo de seguro": this.reclamos_seleccionados[i].tipo_seguro,
            Ubicación: this.reclamos_seleccionados[i].ubicacion,
            "# de riesgo": this.reclamos_seleccionados[i].num_riesgo,
            "Fecha de reclamo": this.reclamos_seleccionados[i].fecha_reclamo,
            "Fecha de pago": this.reclamos_seleccionados[i].fecha_pago,
            Cliente: this.reclamos_seleccionados[i].codigo_cliente,
            Deducible: this.reclamos_seleccionados[i].deducible,
            Participación: this.reclamos_seleccionados[i].participacion,
            "Nombre de cliente": this.reclamos_seleccionados[i].nombre_cliente,
            Aseguradora: this.reclamos_seleccionados[i].aseguradora,
            Ajustador: this.reclamos_seleccionados[i].ajustador,
            Analista: this.reclamos_seleccionados[i].analista,
            Estado: this.reclamos_seleccionados[i].nombre_tipo_reclamo,
            "Valor indemnizado":
              this.reclamos_seleccionados[i].valor_indemnizado,
            Motivo: this.reclamos_seleccionados[i].motivo,
          });
        } else if (
          this.reclamos_seleccionados[i].cod_ramo == "11" ||
          this.reclamos_seleccionados[i].cod_ramo == "12"
        ) {
          //TRANSPORTE MERCADERIA
          obj.push({
            Reclamo: this.reclamos_seleccionados[i].num_reclamo,
            Póliza: this.reclamos_seleccionados[i].num_poliza,
            "Tipo de seguro": this.reclamos_seleccionados[i].tipo_seguro,
            "# de certificado": this.reclamos_seleccionados[i].num_certificado,
            "# de factura": this.reclamos_seleccionados[i].num_factura,
            "Fecha de reclamo": this.reclamos_seleccionados[i].fecha_reclamo,
            "Fecha de pago": this.reclamos_seleccionados[i].fecha_pago,
            Cliente: this.reclamos_seleccionados[i].codigo_cliente,
            Deducible: this.reclamos_seleccionados[i].deducible,
            Participación: this.reclamos_seleccionados[i].participacion,
            "Nombre de cliente": this.reclamos_seleccionados[i].nombre_cliente,
            Aseguradora: this.reclamos_seleccionados[i].aseguradora,
            Ajustador: this.reclamos_seleccionados[i].ajustador,
            Analista: this.reclamos_seleccionados[i].analista,
            Estado: this.reclamos_seleccionados[i].nombre_tipo_reclamo,
            "Valor indemnizado":
              this.reclamos_seleccionados[i].valor_indemnizado,
            Motivo: this.reclamos_seleccionados[i].motivo,
          });
        }
      }      

      let data = XLSX.utils.json_to_sheet(obj);
      const workbook = XLSX.utils.book_new();
      const filename = "Reporte reclamos";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    limpiar() {
      this.num_reclamo = "";
      this.num_poliza = "";
      this.placa_nombre = "";
      this.fecha_reclamo = "";
      this.fecha_pago = "";
      this.cliente = "";
      this.deducible = "";
      this.nom_cliente = "";
      this.aseguradora = "";
      this.taller = "";
      this.caracteristicas_auto = "";
      this.ajustador = "";
      this.analista = "";
      this.estado = "";
      this.valor_indemnizado = "";
      this.motivo = "";
      this.ejecutivo = "";
      this.items_gestiones = [];

      this.flag = 1;
      this.tipo_reclamo = "auto";
      this.dialog_reclamos = false;
    },
    formatoFecha(item) {
      return item ? moment(item).format("YYYY-MM-DD") : "";
    },
    alerta(icon, mensaje) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: icon,
        title: mensaje,
      });
    },
  },
};
</script>